.root {
  height: 100vh;
  width: 100vw;
  background-color: #b3c4ea;
  display: flex;

  position: fixed;
  overflow: hidden;
  .drawer {
    position: fixed;
    top: 0;
    left: -100%; /* Start off-screen */
    transition: left 0.3s ease-in-out;
    z-index: 6;
  }

  .open {
    left: 0; /* Slide in from left */
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    z-index: 5;
  }
  .left {
    height: 100%;
    width: 14vw;
    background-color: #b3c4ea;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .profile {
      margin-top: 15%;
    }

    .sideBar {
      width: 80%;
      height: 40%;
    }

    .logout {
      height: 10%;
      width: 65%;

      .button {
        width: 100%;
        height: 50%;
        // background-color: white;
        border-radius: 10px;
        border-width: 2px;
        border-color: #0d30ac;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
          height: 100%;
          width: 18%;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            font-size: 1.4rem;
          }
        }

        .option {
          padding-left: 0.8vw;
          font-weight: 600;
          font-size: 1rem;
          color: #0d30ac;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 86vw;

    // .top {
    //   height: min-content;
    //   width: 100%;
    // }

    .bottom {
      overflow-y: scroll;
      overflow-x: hidden;
      width: 100%;
      height: 100%;
      background-color: white;
    }
  }
  .right1 {
    width: 100vw !important;
  }
}
@media screen and (max-width: 1024px) {
  .root {
    .left {
      width: 18vw;
      .logout {
        .button {
          .option {
            font-size: 0.9rem;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 820px) {
  .root {
    .left {
      width: 40vw;
      .logout {
        height: 9%;
        .button {
          border-radius: 8px;
          .icon {
            svg {
              font-size: 1.2rem;
            }
          }
          .option {
            font-size: 0.9rem;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 450px) {
  .root {
    .left {
      width: 60vw;
    }
  }
  .warningpopup {
    height: 100vh;
    width: 100vw;
    .content {
      width: 80vw;
      height: 20vh;
      h1 {
        font-size: 2rem;
      }

      .logout {
        height: 8vh;
        width: 30vw;

        .button {
          margin-top: 12%;
          width: 100%;
          height: 50%;
          .icon {
            height: 100%;
            width: 18%;
          }
          .option {
            width: 60%;
            padding-left: 0.8vw;
            span {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
}
