.rootLogin {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .containerMain {
    width: 55%;
    height: auto;
    margin: auto;

    .container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      // background-color: green;
      .left {
        width: 50%;
        // height: 35vh;
        // height: 500px;
        // background-color: yellow;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .email {
          .header {
            display: flex;
            // justify-content: center;
            height: auto;
            padding-bottom: 4%;
            p {
              font-weight: 600;
              font-size: 3rem;
              color: #0d30ac;
            }
          }
          display: flex;
          flex-direction: column;

          width: 100%;

          margin-bottom: 40px;
          // background-color: red;

          .label {
            font-weight: 600;
            font-size: 1.5vw;
            color: #0d30ac;

            margin-bottom: 7%;
          }

          .input {
            width: 100%;
            height: 3.5rem;
            // background-color: yellow;
            background-color: #f8f8f8;
            border-radius: 5px;

            display: flex;
            align-items: center;

            .icon {
              width: 20%;
              height: 60%;
              display: flex;
              align-items: center;

              .emailIcon {
                width: 100%;
                height: 80%;
                color: #0d30ac;
              }
            }

            .inputEmail {
              // width: 90%;
              // height: 100%;
              // background-color: red;

              input {
                width: 20vw;
                height: 5dvh;
                outline: none;
                border: none;
                background-color: #f8f8f8;

                font-size: 22px;
                font-weight: 500;
                color: #0d30ac;

                &:focus {
                  outline: none;
                }
              }
            }
          }
        }

        .buttons {
          width: 100%;
          display: flex;
          .submit {
            width: 8vw;
            height: 6vh;
            background-color: #0d30ac;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 8%;
            p {
              color: white;
              font-size: 1.2vw;
              font-weight: 600;
            }

            &:hover {
              cursor: pointer;
            }
          }

          .cancel {
            width: 8vw;
            height: 6vh;
            border: 2px solid #4370cb3c;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #0d30ac;
            font-weight: 600;
            font-size: 1rem;
            cursor: pointer;
            .link {
              text-decoration: underline;
              line-height: 15px;
            }
          }
        }
      }

      .right {
        width: 24vw;
        height: 50vh;
        border: 1px solid #d9d9ff;
        margin-left: 6%;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 88%;
          height: auto;
        }
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    .containerMain {
      width: 80%;
      .container {
        .left {
          .email {
            .header {
              p {
                font-size: 2.7rem;
              }
            }
            .label {
              font-size: 1.5vw;
            }

            .input {
              .inputEmail {
                input {
                  width: 20vw;
                  height: 100%;
                  &:focus {
                    outline: none;
                  }
                }
              }
            }
          }

          .buttons {
            width: 100%;
            display: flex;
            .submit {
              width: 12vw;
              height: 4vh;
              p {
                font-size: 1.2rem;
              }
            }

            .cancel {
              width: 12vw;
              height: 4vh;
              font-size: 1.2rem;
            }
          }
        }

        .right {
          width: 40vw;
          height: 40vw;
          img {
            width: 80%;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 450px) {
    .containerMain {
      width: 100vw;
      height: auto;
      margin: auto;
      .header {
        width: 100%;
        height: auto;
        margin: 10% auto 5% auto;
        p {
          font-size: 2rem;
        }
      }

      .container {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        .left {
          width: 100vw;
          height: auto;
          margin-top: 5%;
          .email {
            width: 80vw;
            margin-bottom: 20px;
            .label {
              font-size: 1.1rem;
              margin-bottom: 5%;
            }
            .input {
              .inputEmail {
                input {
                  width: 60vw;
                }
              }
            }
          }

          .password {
            width: 80vw;
            .label {
              margin-bottom: 10px;
              .pass {
                font-size: 1.1rem;
              }

              .forgot {
                font-size: 0.8rem;
                font-weight: 500;
              }
            }

            .input {
              .icon {
                width: 16vw;
                height: 60%;
              }

              .inputPassword {
                input {
                  width: 48vw;
                }
              }
              .passicon {
                width: 15vw;
              }
            }
          }

          .buttons {
            width: 50vw;
            height: auto;
            // flex-direction: column;
            margin-top: 5%;
            .submit {
              width: 40vw;
              height: 5vh;
              border-radius: 5px;
              p {
                font-size: 1rem;
              }
            }

            .cancel {
              width: 40vw;
              height: 5vh;

              // margin-top: 3%;
            }
          }
        }

        .right {
          width: 75vw;
          height: 75vw;
          border: 2px solid #d9d9ff;
          margin: 0;
          img {
            width: 56vw;
            height: 56vw;
            border-radius: 50%;
            background-image: linear-gradient(
              to bottom,
              rgb(234, 243, 255),
              rgb(255, 255, 255)
            );
          }
        }
      }
    }
  }
}
