* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Raleway", sans-serif;
    /* max-width: 2200px; */
}

:root {
    --secondary-color: #614d8f;
}
