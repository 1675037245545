.rootLogin {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #0d30ac;

  .containerMain {
    width: 55%;
    height: auto;
    margin: auto;

    .header {
      display: flex;
      justify-content: center;
      height: auto;
      padding-bottom: 2vw;
      p {
        font-weight: 600;
        font-size: 5vw;
      }
    }

    .container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .left {
        width: 45%;
        height: 500px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .email {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-bottom: 40px;
          .label {
            font-weight: 600;
            font-size: 1.5vw;
            margin-bottom: 15px;
          }

          .input {
            width: 100%;
            height: 3.5rem;
            background-color: #f8f8f8;
            border-radius: 5px;
            display: flex;
            align-items: center;
            .icon {
              width: 5vw;
              height: 100%;
              svg {
                font-size: 1.5rem;
              }
            }

            .inputEmail {
              height: 100%;
              flex-grow: 1;
              outline: none;
              border: none;
              background-color: #f8f8f8;
              // font-size: 22px;
              font-weight: 500;
              &:focus {
                outline: none;
              }
            }
          }
        }

        .password {
          display: flex;
          flex-direction: column;
          width: 100%;
          .label {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 15px;
            .pass {
              font-weight: 600;
              font-size: 1.5vw;
              display: block;
            }

            .forgot {
              display: block;
              font-size: 1vw;
              font-weight: 600;
              display: flex;
              align-items: center;
              &:hover {
                cursor: pointer;
              }
            }
          }

          .input {
            width: 100%;
            height: 3.5rem;
            background-color: #f8f8f8;
            border-radius: 5px;
            display: flex;
            align-items: center;
            .icon {
              width: 5vw;
              height: 100%;
              svg {
                font-size: 1.5rem;
              }
            }

            .inputPassword {
              height: 100%;
              flex-grow: 1;
              outline: none;
              border: none;
              background-color: #f8f8f8;
              // font-size: 22px;
              font-weight: 500;
              color: #0d30ac;
              &:focus {
                outline: none;
              }
            }
          }
        }

        .error {
          color: red;
        }
        .buttons {
          width: 25vw;
          height: 8rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .signin {
            width: 40%;
            height: 45%;
            margin: auto;
            background-color: #0d30ac;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            p {
              color: white;
              font-size: 1.2vw;
              font-weight: 600;
            }
            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      .right {
        width: 30vw;
        height: 55vh;
        border: 1px solid #d9d9ff;
        margin-left: 10%;
        border-radius: 50%;
        img {
          width: 88%;
          height: auto;
        }
      }
    }
  }
  .errorpopup {
    width: 20vw;
    height: 10vh;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    border-radius: 5px;
    background-color: rgb(255, 78, 38);
    position: absolute;
    top: 5%;
    right: 5%;
    z-index: 6;
  }

  .errorpopup p {
    color: white;
  }
  @media only screen and (max-width: 1024px) {
    .errorpopup {
      width: 40vw;
      height: 8vh;
    }
    .containerMain {
      width: 100vw;
      .header {
        width: 100%;
        margin: 3% auto;
        p {
          font-size: 2.2rem;
        }
      }

      .container {
        flex-direction: column-reverse;
        svg {
          font-size: 1.8rem !important;
        }
        .left {
          width: 100vw;
          height: auto;
          .email {
            width: 60vw;
            margin-bottom: 20px;
            .label {
              font-size: 1.3rem;
              margin-bottom: 10px;
            }
            .input {
              height: 4vh;
              .icon {
                width: 10vw;
                height: 100%;
              }
            }
          }

          .password {
            width: 60vw;
            .label {
              margin-bottom: 10px;
              .pass {
                font-size: 1.3rem;
              }

              .forgot {
                font-size: 1rem;
                font-weight: 500;
              }
            }

            .input {
              height: 4vh;
              .icon {
                width: 10vw;
                height: 100%;
              }
            }
          }

          .buttons {
            width: 40vw;
            height: auto;
            margin-top: 5%;
            .signin {
              width: 30vw;
              height: 5vh;
              border-radius: 5px;
              p {
                font-size: 1rem;
              }
            }
          }
        }

        .right {
          width: 46vw;
          height: 46vw;
          border: 2px solid #d9d9ff;
          margin: 0;
          img {
            width: 36vw;
            height: 36vw;
            border-radius: 50%;
            background-image: linear-gradient(
              to bottom,
              rgb(234, 243, 255),
              rgb(255, 255, 255)
            );
          }
        }
      }
    }
  }
  @media only screen and (max-width: 450px) {
    .errorpopup {
      width: 70vw;
    }
    .containerMain {
      .header {
        margin: 0 auto 5% auto;
        p {
          font-size: 2rem;
        }
      }

      .container {
        svg {
          font-size: 1.3rem !important;
        }
        .left {
          .email {
            width: 80vw;
            .label {
              font-size: 1.1rem;
            }
            .input {
              height: 8vw;
              .icon {
                width: 15vw;
              }
              input {
                font-size: 0.9rem;
              }
            }
          }

          .password {
            width: 80vw;
            .label {
              margin-bottom: 10px;
              .pass {
                font-size: 1.1rem;
              }

              .forgot {
                font-size: 0.8rem;
                font-weight: 500;
              }
            }
            .input {
              height: 8vw;
              .icon {
                width: 15vw;
                height: 100%;
              }
              input {
                font-size: 0.9rem;
              }
            }
          }

          .buttons {
            width: 50vw;
            margin-top: 5%;
            .signin {
              width: 40vw;
              margin: auto;
              height: 5vh;
              border-radius: 5px;
              p {
                font-size: 1rem;
              }
            }
          }
        }

        .right {
          width: 75vw;
          height: 75vw;
          border: 2px solid #d9d9ff;
          margin: 0;
          img {
            width: 56vw;
            height: 56vw;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 390px) {
    .containerMain {
      .container {
        .left {
          .email {
            .input {
              height: 10vw;
            }
          }

          .password {
            .input {
              height: 10vw;
            }
          }
        }
      }
    }
  }
}
