.recheckDelete {
  color: black;
  position: absolute;
  right: 20%;
  top: -30%;
  z-index: 5;
  width: 25vw;
  height: 25vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 0px 2px 2px lightgray;
  border-radius: 10px;
  hr {
    border-width: 1.3px;
    border-color: #4370cb3c;
  }
  h3 {
    padding: 3%;
    margin-top: 2%;
    font-weight: 700;
  }
  p {
    padding: 3%;
    font-weight: 500;
  }

  .buttonDelete {
    display: flex;
    justify-content: end;
    padding: 3%;

    .bottoncover {
      width: 13vw;
      display: flex;
      justify-content: space-between;
    }
    .btnCancel {
      border: 2px solid #4370cb3c;
      color: black;
    }
    .btnDelete {
      background-color: #0d1dacdb;
      color: white;
    }
    button {
      height: 4.5vh;
      width: 6vw;
      border-radius: 5px;
      border-width: 0px;
      font-weight: 500;
      cursor: pointer;
    }
  }
}

.icon {
  display: flex;
  justify-content: end;
  width: 90%;
  .threedot {
    svg {
      font-size: 1.5rem;
    }
  }
  .menu {
    width: 10vw;
    height: 15vh;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    box-shadow: 0px 0px 3px 1.5px rgb(184, 198, 220);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .list {
      margin-top: 0;
      // border-radius: 10px;
      padding-left: 14px;
      background-color: rgb(255, 255, 255);
      border-width: 0;
      height: 5vh;
      font-size: 1rem;
      font-weight: 30px;
      font-weight: 600;
      color: rgb(67, 66, 66);
    }

    .list:hover {
      background-color: #286fac3c;
    }
  }
}

@media only screen and (max-width: 450px) {
  .recheckDelete {
    width: 70vw;
    height: 20vh;

    h3 {
      padding: 3%;
      margin-top: 2%;
      font-weight: 600;
    }
    p {
      padding: 3%;
      font-weight: 500;
    }

    .buttonDelete {
      .bottoncover {
        width: auto;
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        gap: 10px;
      }

      button {
        width: auto;
        height: 8vw;
        padding: 0 4%;
      }
    }
  }
  .icon {
    .threedot {
      svg {
        font-size: 1rem;
      }
    }
  }
}
