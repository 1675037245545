.rootClients {
  width: 100%;
  height: 100%;
  padding: 3%;

  .header {
    color: #0d1dac;
    display: flex;
    justify-content: space-between;
    p {
      font-size: 2rem;
      font-weight: 600;
    }
  }

  .container {
    width: 100%;
    min-height: 80vh;
    padding: 3%;
    border-radius: 14px;
    border: 3px solid #4370cb3c;
    margin-top: 2%;
    overflow: scroll;
    /* tableStyles.module.css */

    .tableContainer {
      width: 100%;
      border-collapse: collapse;
      th {
        font-size: 1.1rem;
      }
      .row {
        max-width: 20vw;
      }
      .rowComment {
        max-width: 30vw;
        line-height: 1.5rem;
      }
    }
  }
  span {
    color: rgb(0, 94, 255);
    cursor: pointer;
    font-size: 0.9rem;
  }
}
@media only screen and (min-height: 1200px) {
  .rootClients {
    .container {
      min-height: 90vh;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .rootClients {
    .container {
      .tableContainer {
        width: 100%;
        border-collapse: collapse;
        th {
          font-size: 1rem;
        }
        td {
          font-size: 1rem;
        }
      }
    }
  }
}
@media only screen and (max-width: 820px) {
  .rootClients {
    .container {
      .tableContainer {
        width: 100%;
        border-collapse: collapse;
        th {
          font-size: 1rem;
        }
        td {
          font-size: 0.9rem;
        }
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  .rootClients {
    margin-bottom: 16%;
    .header {
      font-size: 0.8rem;
      p {
        font-size: 1.3rem;
      }
      .date {
        display: none;
      }
    }
    .container {
      width: 100%;
      .tableContainer {
        width: 250vw;
        border-collapse: collapse;
        th {
          font-size: 1rem;
        }
        td {
          font-size: 0.9rem;
        }
        .row {
          max-width: 100%;
        }
        .rowComment {
          max-width: 100%;
        }
      }
    }
  }
}
