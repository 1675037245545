.rootExercises {
  width: 100%;
  padding: 3%;
  .header {
    width: 100%;
    top: 0;
    display: flex;
    justify-content: space-between;
    color: #0d30ac;
    .title {
      p {
        font-weight: 700;
        font-size: 36px;
      }
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 14px;
    border: 3px solid #4370cb3c;
    margin-top: 2%;
    padding: 2%;
    .listContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      gap: 30px;

      .card {
        padding: 1.5%;
        width: 36vw;
      }

      .card {
        background-color: #edf0f3;
        display: flex;
        flex-direction: row;
        border-radius: 1vw;
        gap: 20px;

        .imageContainer {
          width: 6vw;
          height: 16vh;
          border-radius: 0.5em;
        }

        .textContainer {
          width: 80%;
          display: flex;
          flex-direction: column;

          .titleBar {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            height: 2vw;

            h3 {
              display: block;
              font-weight: 600;
              font-size: 1.25em;
              color: var(--secondary-color);
              margin-bottom: 5%;
            }

            .icon {
              height: 1.5vw;
              width: 1.22vw;

              .dots {
                height: 100%;
                width: 100%;
                // color: #361f69;
              }
            }
          }

          p {
            text-align: justify;
            font-weight: 400;
            font-size: 14px;
            color: #000000;
            margin-top: 1%;
          }
        }
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .rootExercises {
    .container {
      flex-direction: column;
      width: 100%;
      margin: auto;
      .listContainer {
        flex-direction: column;
        width: 100%;
        margin-top: 1%;
        gap: 15px;
        .card {
          padding: 2%;
          width: 100%;
        }
        .card {
          gap: 5;
          .imageContainer {
            width: 16vw;
            height: 11vh;
            border-radius: 0.5em;
          }
          .textContainer {
            width: 80%;
            margin: 1% auto;
            .titleBar {
              height: auto;
              h3 {
                font-size: 1.4rem;
                margin-bottom: 1%;
                line-height: 18px;
              }
            }
            p {
              margin-top: 1%;
              font-size: 1.2rem;
              line-height: 1.8rem;
              text-align: justify;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  .rootExercises {
    width: 100%;
    margin: 0;
    margin-bottom: 16%;
    .header {
      font-size: 0.8rem;
      margin-bottom: 2%;
      p {
        font-size: 1.3rem !important;
      }
      .date {
        display: none;
      }
    }
    .container {
      flex-direction: column-reverse;
      width: 100%;
      margin: auto;
      .listContainer {
        flex-direction: column;
        gap: 10px;
        .card {
          border-radius: 10px;
          .imageContainer {
            width: 22vw;
            height: 12vh;
          }
          .textContainer {
            width: 80%;
            .titleBar {
              height: auto;
              h3 {
                width: 90%;
                font-size: 0.9rem;
              }
            }
            p {
              font-size: 0.85rem;
              line-height: 1.2rem;
            }
          }
        }
      }
    }
  }
}
