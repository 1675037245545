.rootClients {
  width: 100%;
  padding: 3%;

  .header {
    color: #0d1dac;
    display: flex;
    justify-content: space-between;
    .title {
      font-size: 2rem;
      font-weight: 600;
    }
  }

  .container {
    width: 100%;
    min-height: 80vh;
    padding: 3%;
    border-radius: 14px;
    border: 3px solid #4370cb3c;
    margin-top: 2%;
    .listContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 1.3rem;
      width: 100%;

      .card {
        width: 36vw;
        // flex-grow: 1;
        margin-bottom: 0.6%;
        background-color: #edf0f3;
        padding: 1.8rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-radius: 1vw;

        .imageContainer {
          width: 15%;
          img {
            border: 1px solid #5b8ff7;
            width: 3vw;
            height: 3vw;
            border-radius: 50%;
            overflow: hidden;
            object-fit: cover;
          }
        }

        .textContainer {
          // background-color: yellow;
          width: 80%;

          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          .username {
            p {
              font-weight: 600;
              font-size: 1.53rem;
              color: #0d1dac;
            }
          }
          .viewbox {
            display: flex;
            align-items: center;
            position: relative;
            .buttons {
              margin-right: 1%;
              width: 5vw;
              height: 2vw;
              border-radius: 40px;
              border: 1px solid #361f69;
              background-color: white;
              display: flex;
              align-items: center;
              justify-content: center;
              p {
                // padding: 1rem;
                font-weight: 600;
                font-size: 1.28rem;
                color: #361f69;
              }

              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-height: 1200px) {
  .rootClients {
    .outerContainer {
      min-height: 90vh;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .rootClients {
    width: 100%;

    .container {
      width: 100%;
      .listContainer {
        .card {
          width: 34vw;
          .imageContainer {
            .profilePicture {
              width: 4vw;
              height: 4vw;
            }
          }
          .textContainer {
            .viewbox {
              .buttons {
                width: 8vw;
                height: 2.4vh;
                p {
                  font-size: 0.8rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 820px) {
  .rootClients {
    width: 100vw;

    .header {
      .date {
        display: none;
      }
    }
    .container {
      width: 100%;
      .listContainer {
        .card {
          width: 42vw;
        }

        .card {
          padding: 1.2rem;
          border-radius: 10px;
          .imageContainer {
            .profilePicture {
              width: 5vw;
              height: 5vw;
            }
          }

          .textContainer {
            .username {
              p {
                font-size: 1.33rem;
              }
            }
            .viewbox {
              .buttons {
                width: 10vw;
                height: 2.5vh;
                p {
                  font-size: 1rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  .rootClients {
    margin-bottom: 16%;
    .header {
      font-size: 0.8rem;
      .title {
        font-size: 1.3rem !important;
      }
    }
    .container {
      width: 100%;
      .listContainer {
        flex-direction: column;
        gap: 0;
        .card {
          width: 100%;
        }
        .card {
          margin-bottom: 3%;
          .imageContainer {
            width: 15vw;
            .profilePicture {
              width: 9vw;
              height: 9vw;
            }
          }
          .textContainer {
            .username {
              p {
                font-size: 1.3rem;
              }
            }
            .viewbox {
              gap: 10px;
              .buttons {
                width: 18vw;
                height: 3.5vh;
                p {
                  font-size: 1.1rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 380px) {
  .rootClients {
    .header {
      .title {
        p {
          font-size: 1.2rem;
        }
      }
    }
    .container {
      .listContainer {
        .card {
          padding: 1.1rem;
          .imageContainer {
            .profilePicture {
              width: 10vw;
              height: 10vw;
            }
          }
          .textContainer {
            .username {
              p {
                font-size: 1.13rem;
              }
            }
            .viewbox {
              .buttons {
                height: 3vh;
                p {
                  font-size: 0.9rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
