.rootProfile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .userPicture {
    position: relative;
    div {
      div {
        .profileimage {
          height: 7vw;
          width: 7vw;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
          border-radius: 70%;
          border: 3px solid #0d30ac;
        }
        .cameraIcon {
          font-size: 1.6rem;
          position: absolute;
          bottom: 5%;
          left: 38%;
          opacity: 0.6;
          cursor: pointer;
        }
      }
    }
  }
  .name {
    margin-top: 15px;
    font-weight: 600;
    font-size: 1.5rem;
    text-align: center;
    color: #0d30ac;
  }
}
@media only screen and (max-width: 1024px) {
  .rootProfile {
    .userPicture {
      // border: 4px solid #0d30ac;
      // width: 9vw;
      // height: 9vw;
      div {
        div {
          position: relative;
          .profileimage {
            height: 10vw;
            width: 10vw;
          }
          .cameraIcon {
            font-size: 1.2rem;
            bottom: 5%;
            left: 40%;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 820px) {
  .rootProfile {
    .userPicture {
      // width: 14vw;
      // height: 14vw;
      // border: 3px solid #0d30ac;
      div {
        div {
          position: relative;
          .profileimage {
            height: 13vw;
            width: 13vw;
          }
          .cameraIcon {
            font-size: 1.2rem;
            bottom: 5%;
            left: 40%;
          }
        }
      }
    }
    .name {
      margin-top: 8px;
      font-size: 1.8;
    }
  }
}
@media only screen and (max-width: 450px) {
  .rootProfile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .name {
      font-size: 1.2rem;
    }
    .userPicture {
      div {
        div {
          position: relative;
          .profileimage {
            height: 20vw;
            width: 20vw;
          }
          .cameraIcon {
            font-size: 1rem;
          }
        }
      }
    }
  }
}
