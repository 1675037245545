.container1 {
  width: 100%;
  padding: 3%;
  .client {
    width: 100%;
    display: flex;
    align-items: center;
    img {
      width: 5vw;
      height: 5vw;
      border-radius: 140px;
      border: 0.5px #4d98ed solid;
    }

    .name {
      margin-left: 2%;
      text-align: center;
      color: #0d1dac;
      font-size: 2rem;
      font-family: Raleway;
      font-weight: 600;
    }
  }
  .container {
    width: 100%;
    height: 65vh;
    border: 3px solid #4370cb3c;
    padding: 3%;
    border-radius: 15px;
    margin: 3% auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      width: 60%;
      padding: 3%;
      background: #edf5fe;
      border-radius: 16px;
      box-shadow: 0px 0px 3px 1px lightgray;
      .row {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-top: 3%;
        gap: 20px;
        .element {
          flex-grow: 1;
          h1 {
            color: black;
            font-size: 150%;
            font-family: Raleway;
            font-weight: 500;
            text-transform: capitalize;
            word-wrap: break-word;
          }

          input {
            padding: 1% 5%;
            color: #7e7e7e;
            font-family: Raleway;
            font-weight: 500;
            font-size: 1rem;
            margin-top: 4%;
            width: 100%;
            height: 5vh;
            background: white;
            box-shadow: 0px 4px 8px rgba(211.44, 211.44, 211.44, 0.25);
            border-radius: 8px;
            outline: none;
            border: 0.1px solid #4370cb3c;
            cursor: not-allowed;
          }
        }
      }
      .row:first-child {
        margin: 0;
      }
      .recheckDelete {
        color: black;
        position: absolute;
        z-index: 5;
        top: 20%;
        left: 15%;
        width: 30vw;
        height: 30vh;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: 0px 0px 2px 2px lightgray;
        border-radius: 10px;

        h3 {
          padding: 3%;
          margin-top: 2%;
          font-weight: 700;
        }
        p {
          padding: 3%;
          font-weight: 500;
        }

        .buttonDelete {
          display: flex;
          justify-content: end;
          padding: 3%;

          .bottoncover {
            width: 13vw;
            display: flex;
            justify-content: space-between;
          }
          .btnCancel {
            border: 2px solid #4370cb3c;
            color: black;
            cursor: pointer;
          }
          .btnDelete {
            background-color: #0d1dacdb;
            color: white;
            cursor: pointer;
          }
        }
      }
    }
  }

  .buttons {
    margin-top: 1.5%;
    display: flex;
    width: 20vw;
    margin: auto;
    align-items: center;
    justify-content: space-evenly;
    .btn1 {
      button {
        width: 7vw;
        height: 2.5vw;
        color: white;
        background: #0d1dacd1;
        box-shadow: 0px 4px 8px rgba(108, 159, 255, 0.25);
        border-radius: 8px;
        font-weight: 500;
        font-size: medium;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .btn2 {
      button {
        width: 7vw;
        height: 40px;
        box-shadow: 0px 4px 8px rgba(108, 159, 255, 0.25);
        border-radius: 8px;
        border: 1px #355083 solid;
        background-color: white;
        color: #355083;
        font-size: 17px;
        font-family: Raleway;
        font-weight: 600;
        text-transform: capitalize;
        word-wrap: break-word;
        border-radius: 8px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .container1 {
    .container {
      width: 100%;
      height: 40vh;
      .details {
        width: 80%;
        padding: 3%;
        .row {
          width: 100%;
          .element {
            h1 {
              font-size: 1.3rem;
            }

            input {
              padding: 1% 5%;
              flex-grow: 1;
              height: 3.5vh;
            }
          }
        }

        .recheckDelete {
          width: 40vw;
          height: auto;
          top: 50%;
          left: 10%;
          h3 {
            font-size: 1.3rem;
          }
          p {
            font-size: 1.2rem;
          }

          .buttonDelete {
            .bottoncover {
              width: 18vw;
            }
          }
        }
      }
    }

    .buttons {
      width: 24vw;
      .btn1 {
        button {
          width: 10vw;
          height: 3vh;
        }
      }

      .btn2 {
        button {
          width: 10vw;
          height: 3vh;
        }
      }
    }
  }
}
@media screen and (max-width: 820px) {
  .container1 {
    .container {
      .details {
        .row {
          .element {
            input {
              height: 4vh;
            }
          }
        }

        .recheckDelete {
          width: 45vw;
          top: 34%;
          left: 15%;
          .buttonDelete {
            .bottoncover {
              width: 24vw;
            }
          }
        }
      }
    }

    .buttons {
      width: 34vw;
      .btn1 {
        button {
          width: 14vw;
          height: 4vh;
        }
      }

      .btn2 {
        button {
          width: 14vw;
          height: 4vh;
        }
      }
    }
  }
}
@media screen and (max-width: 450px) {
  .container1 {
    .client {
      img {
        width: 10vw;
        height: 10vw;
      }

      .name {
        font-size: 1.3rem;
      }
    }
    .container {
      width: 100%;
      height: auto;
      .details {
        border-radius: 10px;
        width: 100%;
        padding: 3%;
        margin: 2% auto;
        .row {
          flex-direction: column;
          width: 100%;
          .element {
            h1 {
              font-size: 1rem;
            }

            input {
              font-size: 0.9rem;
              height: 4vh;
              margin-top: 1%;
            }
          }
        }

        .recheckDelete {
          width: 80vw;
          top: 50%;
          left: 4%;
          h3 {
            font-size: 1.1rem;
          }
          p {
            font-size: 1rem;
          }

          .buttonDelete {
            .bottoncover {
              gap: 20px;
              width: 50vw;
              .btnCancel {
                height: 8vw;
              }
              .btnDelete {
                height: 8vw;
              }
            }
          }
        }
      }
    }

    .buttons {
      width: 50vw;
      .btn1 {
        button {
          width: 20vw;
          height: 4vh;
          font-size: 0.9rem;
        }
      }

      .btn2 {
        button {
          width: 20vw;
          height: 4vh;
          font-size: 0.9rem;
        }
      }
    }
  }
}
