.dashboard {
  width: 100%;
  // height: 115vh;
  padding: 4% 3%;
  .header {
    color: #0d1dac;
    display: flex;
    justify-content: space-between;
    p {
      font-size: 2rem;
      font-weight: 600;
    }
  }
  .rootHealthData {
    width: 100%;
    margin: 3% auto;
    .graphs {
      display: flex;
      justify-content: space-around;

      .circle {
        width: 25%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        .head {
          height: 10%;
          p {
            font-size: 1.2rem;
            font-weight: 600;
          }
        }
      }
    }
  }
  .clientChart {
    display: flex;
    justify-content: center;
  }
  .physioChart {
    display: flex;
    justify-content: center;
  }
}
.pieChart {
  margin: auto;
  width: 18vw;
  height: 18vw;
  border-radius: 50%;
  box-shadow: 0px 1px 1px 1px lightgray;
  svg {
    width: 15vw;
    height: 15vw;
  }
  .data {
    font-weight: 600;
    font-size: 2rem;
    color: white;
  }
}
@media only screen and (max-width: 1024px) {
  .dashboard {
    .header {
      p {
        font-size: 1.8rem;
      }
    }
    .rootHealthData {
      margin: 4% auto;
      .graphs {
        .circle {
          width: 25%;
          .head {
            p {
              font-size: 1.2rem;
            }
          }
        }
      }
    }
    .clientChart {
      margin-bottom: 5%;
    }
  }
  .pieChart {
    width: 18vw;
    height: 18vw;
    svg {
      width: 15vw;
      height: 15vw;
    }
    .data {
      font-size: 2rem;
    }
  }
}
@media only screen and (max-width: 820px) {
  .dashboard {
    .header {
      .date {
        display: none;
      }
    }
    .clientChart {
      margin-bottom: 3%;
    }
  }
  .pieChart {
    width: 20vw;
    height: 20vw;
    svg {
      width: 16vw;
      height: 16vw;
    }
    .data {
      font-size: 1.8rem;
    }
  }
}
@media only screen and (max-width: 450px) {
  .dashboard {
    padding-bottom: 15%;
    .header {
      p {
        font-size: 1.2rem;
        font-weight: 600;
      }
    }
    .rootHealthData {
      .graphs {
        .circle {
          width: 40%;
          gap: 10px;
          .head {
            p {
              font-size: 0.8rem;
              line-height: 18px;
            }
          }
        }
      }
    }
  }
  .pieChart {
    margin: auto;
    width: 26vw;
    height: 26vw;
    svg {
      width: 22vw;
      height: 22vw;
    }
    .data {
      font-size: 1rem;
    }
  }
  .clientChart {
    width: 95vw;
    margin: auto;
    margin-bottom: 5%;
  }
  .physioChart {
    width: 95vw;
    margin: auto;
  }
}
@media only screen and (max-width: 380px) {
  .dashboard {
    .rootHealthData {
      .graphs {
        .circle {
          .head {
            p {
              font-size: 0.68rem;
            }
          }
        }
      }
    }
  }
  .clientChart {
    width: 95vw;
    margin: auto;
    margin-bottom: 5%;
  }
  .physioChart {
    width: 95vw;
    margin: auto;
  }
}
