.settings {
  height: 100%;
  width: 100%;
  padding: 3%;
  .header {
    color: #0d1dac;
    display: flex;
    justify-content: space-between;
    p {
      font-size: 2rem;
      font-weight: 600;
    }
  }

  .container {
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
    border: 3px solid #4370cb3c;
    margin-top: 2%;
    form {
      background-color: #f4f9ff;
      padding: 30px 50px;
      //   margin-top: 2%;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 0px 5px 1px lightgray;
      border: 2px solid #4370cb3c;

      .field {
        display: flex;
        flex-direction: column;
        width: 20vw;
        .fieldName {
          font-size: 22px;
          font-weight: 500;
          margin-bottom: 5px;
        }

        .fieldInput {
          margin-top: 1%;
          display: flex;
          width: 100%;
          height: 45px;
          padding: 0 4%;
          font-size: 1.1rem;
          border: 2px solid #4370cb3c;
          outline: none;
          border-radius: 8px;
          background: #fff;
          box-shadow: 0px 4px 8px 0px rgba(211, 211, 211, 0.25);
          .Input {
            height: 100%;
            outline: none;

            flex-grow: 1;
          }
          .icon {
            height: 100%;
            color: gray;
          }
        }
      }

      .error {
        font-size: 20px;
        font-weight: 500;
        color: red;
      }
      .success {
        font-size: 20px;
        font-weight: 500;
        color: rgb(8, 0, 255);
      }

      .button {
        padding: 10px 20px;
        min-width: 100px;
        margin-top: 8%;
        border-radius: 10px;
        color: #fff;
        background-color: #0d30ace1;
        border: none;
        font-size: 20px;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .settings {
    width: 100vw;
    .header {
      font-size: 0.9rem;
      p {
        font-size: 1.3rem;
      }
      .date {
        display: none;
      }
    }

    .container {
      margin-top: 4%;
      border: none;
      align-items: flex-start;
      form {
        width: 100%;
        background-color: #f4f9ff;
        padding: 3%;

        .field {
          display: flex;
          flex-direction: column;
          width: 100%;
          .fieldName {
            font-size: 1.2rem;
            font-weight: 500;
            margin-bottom: 5px;
          }

          .fieldInput {
            width: 100%;
            height: 5vh;
            padding: 0 4%;
            input {
              font-size: 0.9rem;
            }
          }
        }

        .button {
          padding: 0;
          width: 28vw;
          height: 5vh;
          margin-bottom: 4%;
        }
      }
    }
  }
}
