.chart {
  width: 98%;
  margin-top: 3%;
  padding: 2% 3%;
  border-radius: 25px;
  box-shadow: 0px -3.809999942779541px 7.621301651000977px 0px #3b5fc640;
  border: 0.2px solid #d2c7ff33;

  .header {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;

    .title {
      font-size: 1.4rem;
      font-weight: 600;
      color: #2b4ab6;
    }

    .button {
      box-shadow: 0px -3.809999942779541px 7.621301651000977px 0px #3b5fc640;
      width: auto;
      height: auto;
      border-radius: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1% 2%;
      select {
        font-size: 1.2rem;
        font-weight: 600;
        color: #2b4ab6;
        background-color: white;
        width: 8vw;
        border: 1px solid transparent;
        padding: 2px 10px;
        option {
          font-weight: 600;
          border-radius: 10px;
        }
      }
      select:focus {
        border-color: transparent !important; /* Set border color to transparent */
        outline: none !important; /* Remove the default outline */
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  .chartMain {
    height: 40vh;
  }
}
@media only screen and (max-width: 1024px) {
  .chart {
    padding: 3% 4%;
    .header {
      .button {
        padding: 1% 2%;
        select {
          width: 10vw;
        }
      }
    }
    .chartMain {
      height: 30vh;
    }
  }
}
@media only screen and (max-width: 820px) {
  .chart {
    .header {
      .button {
        padding: 1% 2%;
        select {
          width: 13vw;
        }
      }
    }
    .chartMain {
      height: 24vh;
    }
  }
}
@media only screen and (max-width: 450px) {
  .chart {
    width: 100%;
    padding: 2% 4%;
    border-radius: 10px;
    .header {
      .title {
        font-size: 1.2rem;
      }
      .button {
        border-radius: 10px;
        padding: 1% 2%;
        select {
          width: 22vw;
        }
      }
    }
    .chartMain {
      height: 60vw;
      width: 100%;
      margin: auto;
    }
  }
}
@media only screen and (max-width: 390px) {
  .chart {
    .header {
      .title {
        font-size: 1.1rem;
      }
      .button {
        padding: 1% 2%;
        select {
          width: 20vw;
          font-size: 1rem;
          padding: 0;
        }
      }
    }
    .chartMain {
      height: 60vw;
      width: 100%;
      margin: auto;
    }
  }
}
